import { useEffect, useRef } from "react"

import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

export const useFadeIn = () => {
  const elementRef = useRef(null)

  function animateFrom(elem, direction = 1) {
    direction = direction || 1
    var x = 0,
      y = direction * 100
    if (
      elem.classList.contains("left") ||
      elem.classList.contains("reveal-left")
    ) {
      x = -100
      y = 0
    } else if (
      elem.classList.contains("right") ||
      elem.classList.contains("reveal-right")
    ) {
      x = 100
      y = 0
    }
    elem.style.transform = "translate(" + x + "px, " + y + "px)"
    elem.style.opacity = "0"
    gsap.fromTo(
      elem,
      { x: x, y: y, autoAlpha: 0 },
      {
        duration: 2.25,
        x: 0,
        y: 0,
        autoAlpha: 1,
        ease: "expo",
        lazy: false,
        // overwrite: "auto"
      }
    )
  }

  function hide(elem) {
    gsap.set(elem, { autoAlpha: 0 })
  }

  useEffect(() => {
    const element = elementRef.current
    gsap.registerPlugin(ScrollTrigger)
    if (element) {
      ScrollTrigger.create({
        trigger: element,
        onEnter: function () {
          animateFrom(element)
        },
        onEnterBack: function () {
          animateFrom(element, -1)
        },
        onLeave: function () {
          hide(element)
        }, // assure that the element is hidden when scrolled into view
      })
    }
  })

  return { ref: elementRef }
}
