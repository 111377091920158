import React, { createContext, useState } from "react"
import { LocalStorage, LocalStorageKeys } from "../utils"

export const CwvContext = createContext({})
export const CwvMonthContext = createContext()
export const CwvSelectedIndex = createContext(0)
export const CwvDeviceContext = createContext("")
export const MarketIndexContext = createContext(0)
// export const CwvContextPages = createContext({})

export const ZendeskContext = createContext({})

export const ZendeskProvider = ({ children }) => {
  const user_info = JSON.parse(LocalStorage.get(LocalStorageKeys.USER_INFO))
  const [id, setId] = useState(
    user_info?.requester_Id ? user_info.requester_Id : 0
  ) //User requester ID

  const [firstName, setFirstName] = useState(
    user_info?.firstName ? user_info.firstName : 0
  )

  const [counter, setCounter] = useState(0) //Used to run the useEffect again
  const [tickets, setTickets] = useState([]) //All user tickets will be placed here
  const [activeTickets, setActiveTickets] = useState([]) //All user active tickets will be placed here
  const [ticketLength, setTicketLength] = useState(0) //Active Ticket length will be placed here

  const [ticketObject, setTicketObject] = useState(0)

  return (
    <ZendeskContext.Provider
      value={{
        id,
        setId,
        counter,
        setCounter,
        tickets,
        setTickets,
        ticketLength,
        setTicketLength,
        activeTickets,
        setActiveTickets,
        ticketObject,
        setTicketObject,
        firstName,
        setFirstName,
      }}
    >
      {children}
    </ZendeskContext.Provider>
  )
}

export const LayoutContext = createContext({})

export const LayoutProvider = ({ children }) => {
  const [quickView, setQuickView] = useState(false)
  const [isDarkMode, setIsDarkMode] = useState(false) // Initialize dark mode state
  const [visible, setVisible] = useState(false)
  return (
    <LayoutContext.Provider
      value={{
        quickView,
        setQuickView,
        isDarkMode,
        setIsDarkMode,
        visible,
        setVisible,
      }}
    >
      {children}
    </LayoutContext.Provider>
  )
}

export const CwvProvider = ({ children }) => {
  const [cardShow, setCardShow] = useState(false)
  const [cwvData, setCwvData] = useState({})
  const [cwvStatus, setCwvStatus] = useState({})
  const [cwvResult, setCwvResult] = useState({})
  const [cwvError, setCwvError] = useState({})
  const [cwvDevice, setCwvDevice] = useState("PHONE")
  const [cwvType, setCwvType] = useState("origin")
  const [url, setUrl] = useState("")

  const [brand, setBrand] = useState("lipton")
  const [market, setMarket] = useState("usa")
  const [date, setDate] = useState(new Date().toISOString().split("T")[0])
  const [device, setDevice] = useState("PHONE")
  const [marketIdx, setMarketIdx] = useState(0)

  const [startDate, setStartDate] = useState(
    new Date(new Date().setMonth(new Date().getMonth() - 1))
  )
  const [endDate, setEndDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 1))
  )
  const [showing, setShowing] = useState("last 30 days")

  const [days, setDays] = useState(30)

  return (
    <CwvContext.Provider
      value={{
        cardShow,
        setCardShow,
        cwvData,
        setCwvData,
        cwvStatus,
        setCwvStatus,
        cwvResult,
        setCwvResult,
        cwvError,
        setCwvError,
        cwvDevice,
        setCwvDevice,
        cwvType,
        setCwvType,
        url,
        setUrl,
        brand,
        setBrand,
        market,
        setMarket,
        date,
        setDate,
        device,
        setDevice,
        marketIdx,
        setMarketIdx,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        showing,
        setShowing,
        days,
        setDays,
      }}
    >
      {children}
    </CwvContext.Provider>
  )
}
