/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "firebase/auth"
import "firebase/firestore"
import React from "react"
import Layout from "./src/components/Layout/Layout"

import AuthProvider from "./src/context/auth"
import {
  LayoutProvider,
  ZendeskProvider,
  CwvProvider,
} from "./src/context/context"
import ProfileProvider from "./src/context/profile_data"

export const wrapPageElement = ({ element }) => {
  return (
    <AuthProvider>
      <ProfileProvider>
        <ZendeskProvider>
          <LayoutProvider>
            <CwvProvider>
              <Layout>{element}</Layout>
            </CwvProvider>
          </LayoutProvider>
        </ZendeskProvider>
      </ProfileProvider>
    </AuthProvider>
  )
}
