import React, { useState } from "react"
import { navigate } from "gatsby"

interface IsState {
  response?: any
  loading: Boolean
  error?: any
}

interface formData {
  listId: number
  body: any
}

export const useSaveCWVUrls = () => {
  const [state, setState] = useState<IsState | null>({
    response: null,
    loading: false,
    error: null,
  })

  const saveCWVUrls = async (data: formData) => {
    setState({ response: null, loading: true, error: null })
    try {
      const output = await fetch("/.netlify/functions/clickup-cwv-urls", {
        method: "POST",
        body: JSON.stringify(data),
      })
      const res = await output.json()
      console.log("res", res)
      setState({ response: res, loading: false, error: null })
      // navigate('/thank-you/')
    } catch (error) {
      setState({
        response: null,
        loading: false,
        error: error,
      })
    }
  }

  return [saveCWVUrls, state]
}
