// navigationUtils.ts

export const shouldShowDarkModeToggle = (pathname: string): boolean => {
  const noDarkModePages = [
    "/cwv-performance/",
    "/page-dqm/",
    "/cwv-analytics/",
    "/diagnostics-page-performance/",
    "/cwv-page-performance/",
    "/cwv-diagnostics",
    "/diagnostics-page-level",
    "/core-web-vitals-report",
  ]

  return !noDarkModePages.some(route => pathname.startsWith(route))
}
